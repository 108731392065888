@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply relative overflow-x-hidden max-w-full bg-lm-shoe;
  }

  .smooth {
    @apply transition-all duration-200;
  }

  .disabled {
    @apply pointer-events-none opacity-30;
  }

  a,
  a:hover {
    @apply no-underline;
  }

  button {
    @apply border-none bg-transparent;
  }

  button,
  input,
  textarea {
    @apply !rounded-none;
  }

  button:focus,
  input:focus,
  textarea:focus {
    @apply outline-none;
  }

  textarea {
    @apply resize-none;
  }
}

@layer components {
  .button-cadmium {
    @apply button-base button-base-ring focus:ring-offset-white focus:ring-lm-cadmium text-base md:text-xl font-bold text-white border border-transparent bg-lm-cadmium hover:!text-lm-cadmium hover:bg-lm-beluga uppercase;
  }

  .button-cadmium-lg {
    @apply button-base-lg button-base-ring focus:ring-offset-white focus:ring-lm-cadmium uppercase !text-base md:!text-xl font-bold text-white border border-transparent bg-lm-cadmium hover:text-lm-cadmium hover:bg-lm-beluga;
  }

  .button-white-lg {
    @apply button-base-lg button-base-ring focus:ring-offset-white focus:ring-lm-cadmium uppercase !text-base md:!text-xl font-bold text-black border border-transparent bg-white hover:text-white hover:bg-lm-cadmium;
  }

  .button-white-outline {
    @apply button-base button-base-ring focus:ring-offset-white focus:ring-lm-cadmium text-base md:text-xl font-bold text-white border-white border bg-transparent hover:bg-white hover:text-black;
  }

  .button-bare {
    @apply button-base button-base-ring !p-0 uppercase hover:underline focus:ring-offset-white focus:ring-lm-cadmium !text-xl font-bold text-lm-cadmium border-transparent border bg-transparent hover:bg-transparent hover:text-lm-cadmium;
  }

  .navbutton {
    @apply text-2xl lg:text-sm xl:text-lg text-white uppercase font-secondary;
  }

  .header-gradient {
    background: linear-gradient(180deg, #000000 22.92%, rgba(33, 33, 33, 0.0001) 100%);
  }

  .footer-gradient {
    background: linear-gradient(180deg, #000000 0%, rgba(33, 33, 33, 0.0001) 100%);
  }
}

@layer utilities {
  .ellipsis {
    @apply overflow-hidden text-ellipsis whitespace-nowrap;
  }

  .button-base-ring {
    @apply focus:ring-2 focus:ring-offset-2 transition-all;
  }

  .button-base {
    @apply font-main rounded-none inline-flex text-sm sm:text-base font-semibold whitespace-nowrap justify-center items-center gap-3 children-path:transition-all children-path:duration-200 px-2 py-1 sm:px-4 sm:py-2 smooth;
  }

  .button-base-lg {
    @apply font-main rounded-none inline-flex text-sm sm:text-base font-semibold whitespace-nowrap justify-center items-center gap-3 children-path:transition-all children-path:duration-200 px-4 py-2 sm:px-6 sm:py-3 smooth;
  }

  .section {
    @apply py-10 sm:py-10 md:py-14 lg:py-32;
  }

  .section-sm {
    @apply py-5 sm:py-8 md:py-16 lg:py-20;
  }

  .section + .section {
    @apply !pt-0;
  }

  .text-section {
    @apply py-10 sm:py-10 md:py-14 lg:py-32;
  }

  .text-section + .text-section {
    @apply !pt-0;
  }
}

/* ----------START PARSECONTENT---------- */
.parsecontent h1 {
  @apply mb-5 text-4xl md:text-6xl lg:text-[80px] lg:leading-[1.2] font-secondary uppercase font-semibold;
}

.parsecontent h2 {
  @apply mb-5 text-3xl md:text-4xl lg:text-[80px] lg:leading-[1.2] font-secondary uppercase font-semibold;
}

.parsecontent h3 {
  @apply mb-3 text-xl lg:text-3xl font-secondary uppercase font-semibold;
}

.parsecontent p {
  @apply font-light leading-[2] text-base font-main;
}

.parsecontent blockquote > p {
  @apply text-2xl lg:text-3xl leading-[45px] pb-5 font-bold;
}

.parsecontent strong,
.parsecontent b,
.parsecontent h1 strong,
.parsecontent h1 b,
.parsecontent h2 strong,
.parsecontent h2 b,
.parsecontent h3 strong,
.parsecontent h3 b,
.parsecontent h4 strong,
.parsecontent h4 b,
.parsecontent h5 strong,
.parsecontent h5 b {
  @apply font-bold;
}

.parsecontent .button-cadmium {
  @apply text-white;
}

.parsecontent h1:last-child,
.parsecontent h2:last-child,
.parsecontent h3:last-child,
.parsecontent h4:last-child,
.parsecontent h5:last-child,
.parsecontent p:last-child {
  @apply mb-0;
}

.parsecontent a {
  @apply font-bold;
}

.parsecontent a:hover {
  @apply underline;
}

.parsecontent ul {
  @apply pl-10;
}

.parsecontent li {
  @apply text-base relative font-main leading-8 xl:leading-7 text-white py-[2px] lg:py-1 font-bold;
}

.parsecontent li::before {
  @apply content-[url('/images/check.svg')] h-6 w-6 absolute -left-10 top-[5px];
}

.alignright {
  @apply float-right text-center;
}

.aligncenter {
  @apply my-0 mx-auto block;
}

.wp-caption {
  @apply max-w-full;
}

.wp-caption-text {
  @apply text-center text-base font-bold;
}
/* ----------END PARSECONTENT---------- */

/* ----------START FORM---------- */
.form-duuf-group {
  @apply mb-3 sm:mb-5;
}

.form-duuf-input,
.form-duuf-textarea {
  @apply h-9 sm:h-12 font-main px-4 w-full rounded-none border border-lm-explosive bg-white text-black !shadow-none !ring-0 placeholder:text-black;
}

.form-duuf-textarea {
  @apply pt-3 min-h-[80px] sm:min-h-[120px];
}

.form-duuf-file-input {
  @apply text-black cursor-pointer file:mr-4 file:font-main file:font-semibold file:border-none file:bg-lm-cadmium file:py-2 file:px-4 file:text-white file:hover:bg-white file:hover:text-lm-cadmium file:hover:transition-all;
}

.darkform .form-duuf-file-input,
.darkform .form-duuf-file-name {
  @apply text-white;
}

.form-duuf-checkbox {
  @apply mr-4 h-7 w-7 rounded-md border-none bg-white text-black focus:border-none focus:outline-none active:border-none active:outline-none;
}

.darkform .form-duuf-checkbox-label {
  @apply text-white font-main;
}

.form-duuf-select__control {
  @apply h-12 !border !border-lm-explosive !rounded-none !bg-white !shadow-none;
}

.form-duuf-select__menu {
  @apply !rounded-none;
}

.form-duuf-select__menu-list {
  @apply px-4 py-0;
}

.form-duuf-select__option {
  @apply my-1 cursor-pointer rounded-none transition-all;
}

.form-duuf-select__indicator-separator {
  @apply hidden;
}

.form-duuf-select__option:first-of-type {
  @apply mt-3;
}

.form-duuf-select__option:last-of-type {
  @apply mb-3;
}

.luxe-form .form-duuf-group-section {
  @apply hidden;
}

.luxe-form .form-duuf-group-radio > div {
  @apply flex flex-col gap-2;
}

.form-duuf-select__option:active {
  @apply !bg-black !text-white;
}

.form-duuf-select__option--is-focused {
  @apply !bg-white;
}

.form-duuf-select__option--is-selected {
  @apply !bg-lm-cadmium;
}

.form-duuf-select__input {
  @apply !border-none !shadow-none !outline-none;
}

.form-duuf-select__value-container {
  @apply !px-4;
}

.form-duuf-select__single-value {
  @apply !top-[30px] !left-[16px] !m-0 !text-black;
}

.form-duuf-select__placeholder {
  @apply !top-[24px] !left-[16px] !m-0 !text-black;
}

.form-duuf-group {
  @apply relative;
}

.form-duuf-error {
  @apply text-sm text-red-500;
}

.form-duuf-label {
  @apply font-secondary block mb-2;
}

.darkform .form-duuf-label {
  @apply text-white;
}

.form-duuf-submit-disabled {
  @apply opacity-50 hover:bg-blue-500;
}

.form-duuf-group-submit {
  @apply flex justify-end mb-0 pt-4;
}

.form-duuf-file-name {
  @apply hidden;
}

.form-duuf-submit > button {
  @apply button-cadmium;
}

.form-duuf-loading {
  @apply absolute left-0 top-0 z-10 h-full w-full smooth bg-black bg-opacity-60;
}

.form-duuf-group-consent a {
  @apply text-lm-cadmium underline hover:no-underline;
}
/* ----------START FORM---------- */

#nprogress .bar {
  background: #f68a2e !important;
  height: 3px !important;
  shadow: none !important;
}

#nprogress .spinner-icon {
  border-top-color: #f68a2e !important;
  border-left-color: #f68a2e !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #f68a2e, 0 0 5px #f68a2e !important;
}
